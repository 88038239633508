<template>
  <div class="d-flex flex-column flex-center flex-column-fluid h-100">
    <div class="d-flex flex-column flex-center text-center p-10">
      <div class="card card-flush h-100 py-3">
        <div class="card-body py-15 py-lg-20">
          <h1 class="fw-bolder fs-2qx text-danger mb-4">
            {{
              $t(
                "Components.PageUnderConstruction.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h1>
          <div class="fw-semibold fs-5 mb-8">
            {{
              $t(
                "Components.PageUnderConstruction.Description",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <div class="mt-3">
            <img
              :src="require('@/assets/images/error/PageLayoutNone.png')"
              alt=""
              class="mw-100"
            />
          </div>
          <div class="mt-5">
            <router-link to="/" class="btn btn-primary">
              <i class="bi bi-house-door-fill me-1"></i>
              {{
                $t(
                  "Buttons.ReturnHome",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageUnderConstruction",
  mounted() {
    this.$root.changeDocumentTitle(
      this.$t(
        "Components.PageUnderConstruction.Title",
        {},
        { locale: this.$store.state.activeLang }
      )
    );
  },
};
</script>
